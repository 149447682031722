<template>
  <div id="create-manager">
    <form-template 
      v-bind="config" 
      :current-role="userData.role"
      :hospital-data="hospitalData"
      @action:get-hospital-data="getHospitalData"
      @submit="submitCreate" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// mixins
import crudRequestMixin from "@/mixins/listPages/httpRequestMixins";

// components
import FormTemplate from "@/components/templates/FormTemplate";

export default {
  name: "ManagerForm",
  mixins: [crudRequestMixin],
  components: {
    FormTemplate,
  },
  data: () => ({
    config: {
      title: "Add New Physicians",
      javaClass: "ch.galliard.pompe.api.dto.pages.User",
      role: "PHYSICIAN",
      currentHospital: null,
      formLoading: false,
      form: [
        {
          name: "firstName",
          label: "First Name",
          component: "text-field",
          type: "text",
          placeholder: "Enter First Name",
          mandatory: true,
        },
        {
          name: "lastName",
          label: "Last Name",
          component: "text-field",
          type: "text",
          placeholder: "Enter Last Name",
          mandatory: true,
        },
        {
          name: "email",
          label: "Email",
          component: "text-field",
          type: "email",
          placeholder: "Enter Email",
          mandatory: true,
        },
        {
          name: "language",
          label: "Language",
          component: "dropdown",
          options: [
            { text: "Select Language", value: null },
            { text: "German", value: "German" },
            { text: "Italian", value: "Italian" },
            { text: "English", value: "English" },
            { text: "French", value: "French" },
          ],
          mandatory: true,
        },
        {
          name: "workplaceName",
          label: "Department of the Hospital",
          component: "text-field",
          type: "text",
          placeholder: "Enter Department Name",
        },
        {
          name: "phoneNumber",
          label: "Phone Number",
          component: "phone-number-input",
          placeholder: "Enter Phone Number",
        },
        {
          name: "mobileNumber",
          label: "Mobile Number",
          component: "phone-number-input",
          placeholder: "Enter Mobile Number",
        },
        {
          name: "city",
          label: "City",
          component: "text-field",
          type: "text",
          placeholder: "Enter City",
        },
        {
          name: "postcode",
          label: "Postcode",
          component: "text-field",
          type: "number",
          placeholder: "Enter Postcode",
        },
      ],
      formEdit: {},
      backButtonDestination: "/web/summary/treatingPhysicians",
    },
    hospitalData: {}
  }),
  computed: {
    isEdit() {
      return this.$route.path.includes("edit");
    },
    ...mapGetters({
      userData: 'general/getUserData'
    }),
  },
  mounted() {
    if (this.isEdit) {
      this.populateForm();
      this.config.title = "Edit Treating Physicians";

      let index = this.config.form.findIndex(el => el.name === 'password');
      this.config.form.splice(index,1); // remove password
    }

    if (this.userData.role !== 'ADMIN') {
      this.config.currentHospital = this.userData.hospitalId
    } else this.getHospitalList(); 
  },
  methods: {
    getHospitalDataSuccess(response) {
      this.hospitalData = response.data
    },
    getHospitalList() {
      this.$requestService.get(
        this.$apiEndpoints.hospitals, 
        {},
        this.handleGetHospitalSuccess,
        this.handleGetHospitalFailed
      );
    },
    handleGetHospitalSuccess(response) {
      const options = response.data.content.map(el => { 
        return { text: el.name, value: el.id }
      });
      this.config.form = [
        {
          name: 'hospitalId',
          label: 'Hospital',
          component: 'dropdown',
          mandatory: true,
          options: [
            { text: 'Select Hospital', value: null },
            ...options
          ]
        },
        ...this.config.form
      ]
    },
    handleGetHospitalFailed(e) {
      this.$vToastify.error(e.message);
    }
  },
};
</script>

<style></style>
